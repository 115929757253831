<script setup>
import { onMounted, onUnmounted } from "vue";
import { useBlog } from "~/composables/useBlog";

const seoTitle = "Công ty cổ phần Miraway giải pháp công nghệ";
const seoDescription =
  "Miraway - doanh nghiệp cung cấp giải pháp công nghệ trải nghiệm giao dịch khách hàng top 20 thế giới";

useHead({
  link: [
    {
      rel: "preload",
      as: "image",
      href: "/images/home/bg-intro.webp",
    },
  ],
  title: seoTitle,
  meta: [
    { name: "keywords", content: "miraway" },
    { name: "og:title", content: seoTitle },
    { name: "twitter:title", content: seoTitle },
    { name: "description", content: seoDescription },
    { name: "og:description", content: seoDescription },
    { name: "twitter:description", content: seoDescription },
  ],
});

const services = [
  {
    image: "/images/icons/ic-self-service.svg",
    alt: "self-service",
    title: "GIẢI PHÁP KIOSK TỰ PHỤC VỤ",
    redirect: "/giai-phap/self-service-kiosk",
  },
  {
    image: "/images/icons/ic-cx.svg",
    alt: "cdp-customer-data-platform",
    title: "CDP - NỀN TẢNG PHÂN TÍCH DỮ LIỆU KHÁCH HÀNG",
    redirect: "/giai-phap/cdp",
  },
  {
    image: "/images/icons/ic-solution-qrx.svg",
    alt: "qrx-solutions",
    title: "QRX - GIẢI PHÁP QUẢN LÝ PHÂN PHỐI D2C",
    redirect: "https://qrx.com.vn/",
  },
  {
    image: "/images/icons/ic-digital.svg",
    alt: "digital-signage",
    title: "DIGITAL SIGNAGE - QUẢN LÝ MÀN HÌNH THÔNG MINH",
    redirect: "/giai-phap/digital-signage",
  },
  {
    image: "/images/icons/ic-booking.svg",
    alt: "booking-and-smart-queue",
    title: "ĐẶT LỊCH, XẾP HÀNG & PHẢN HỒI THÔNG MINH",
    redirect: "/giai-phap/he-thong-xep-hang-tu-dong",
  },
];

const typicalSolutions = [
  {
    image: "/images/home/all-solution.png",
    alt: "gia-phap-tieu-bieu",
    links: [
      {
        to: "/giai-phap/cx",
        ariaLabel: "customer-experience-platform",
      },
      {
        to: "/giai-phap/self-service-kiosk",
        ariaLabel: "self-service-kiosk",
      },
      {
        to: "/giai-phap/cdp",
        ariaLabel: "customer-data-platform",
      },
      {
        to: "/giai-phap/vms-camera",
        ariaLabel: "vms-camera",
      },
      {
        to: "/giai-phap/may-ban-hang-tu-dong",
        ariaLabel: "may-ban-hang-tu-dong",
      },
      {
        to: "/giai-phap/he-thong-xep-hang-tu-dong",
        ariaLabel: "he-thong-xep-hang-tu-dong",
      },
      {
        to: "https://qrx.com.vn/",
        targetBlank: true,
        ariaLabel: "qr-experience",
      },
      {
        to: "/giai-phap/digital-signage",
        ariaLabel: "digital-signage",
      },
    ],
  },
  {
    title: "Giải pháp Kiosk giao dịch tự phục vụ",
    description: "Giải pháp Kiosk tự động cung cấp dịch vụ thông minh và tiếp cận khách hàng 24/7",
    redirect: "/giai-phap/self-service-kiosk",
    image: "/images/home/self-service-kiosk.png",
    alt: "giai-phap-kiosk-giao-dich-tu-phuc-vu",
    classImgMobile: "max-lg:w-[100vw]",
  },
  {
    title: "Hệ thống quảng cáo màn hình số thông minh Digital Signage",
    description:
      "Giải pháp đột phá về nhận diện thương hiệu và tiếp cận khách hàng mục tiêu. Truyền tải thông điệp đến đúng khách hàng, đúng thời điểm.",
    redirect: "/giai-phap/digital-signage",
    image: "/images/home/digital-signage.png",
    alt: "he-thong-quang-cao-man-hinh-so-thong-minh-digital-signage",
    classImgMobile: "max-lg:w-[80vw]",
  },
  {
    title: "Giải pháp quản lý video VMS-AI Camera",
    description:
      "Công nghệ tiên tiến ứng dụng trí tuệ nhân tạo giúp tự động xác định và nhận diện người thông qua khuôn mặt, sinh trắc học, RFID, QR Code,... Từ đó, phân luồng để đáp ứng nhu cầu, phục vụ khách hàng một cách chuyên nghiệp nhất.",
    redirect: "/giai-phap/vms-camera",
    image: "/images/home/vms-camera.png",
    alt: "giai-phap-quan-ly-video-vms-ai-camera",
    classImgMobile: "max-lg:w-[80vw]",
  },
  {
    title: "Hệ thống đặt lịch & xếp hàng thông minh",
    description:
      "Giảm thời gian chờ của khách hàng, tối ưu việc quản lý hàng đợi và tăng doanh thu với Giải pháp Đặt lịch & Xếp hàng thông minh của Miraway.",
    redirect: "/giai-phap/he-thong-xep-hang-tu-dong",
    image: "/images/home/dat-lich.png",
    alt: "he-thong-dat-lich-and-xep-hang-thong-minh",
    classImgMobile: "max-lg:w-[80vw]",
  },
  {
    title: "Nền tảng Phân tích dữ liệu khách hàng - CDP",
    description:
      "Nền tảng tổng hợp dữ liệu khách hàng tại tất cả các điểm tiếp xúc (trực tuyến và trực tiếp) vào một nền tảng duy nhất.",
    redirect: "/giai-phap/cdp",
    image: "/images/home/cdp.png",
    alt: "nen-tang-phan-tich-du-lieu-khach-hang-cd",
    classImgMobile: "max-lg:w-[80vw]",
  },
  {
    title: "Nền tảng trải nghiệm khách hàng đa kênh (CX PLATFORM)",
    description:
      "Hệ thống quản lý và giám sát trải nghiệm khách hàng tại tất cả điểm chạm trong hành trình khách hàng.",
    redirect: "/giai-phap/cx",
    image: "/images/home/cx-platform.png",
    alt: "nen-tang-trai-nghiem-khach-hang-da-kenh-cx-platform",
    classImgMobile: "max-lg:w-[65vw]",
  },
  {
    title: "Giải pháp quản trị trải nghiệm bán hàng QRX",
    description:
      "Cung cấp các giải pháp ứng dụng QR Code theo đặc thù chuyên môn Đem lại sự trải nghiệm và sự hài lòng cao nhất tới khách hàng. Giải quyết các vấn đề trên kênh bán truyền thống và kênh bán online.",
    redirect: "https://qrx.com.vn",
    targetBlank: true,
    image: "/images/home/rubik.png",
    alt: "giai-phap-quan-tri-trai-nghiem-ban-hang-qrx",
  },
];

const handleAnimate = () => {
  const winH = window.innerHeight + 180;
  const winW = window.innerWidth + 180;
  const clientH = document.documentElement.clientHeight + 180;
  const clientW = document.documentElement.clientWidth + 180;

  const numSysData = document?.getElementById("number-system-data")?.getBoundingClientRect();
  if (
    numSysData &&
    numSysData.top >= 0 &&
    numSysData.left >= 0 &&
    numSysData.bottom <= (winH || clientH) &&
    numSysData.right <= (winW || clientW)
  ) {
    window.removeEventListener("scroll", handleAnimate);

    const counters = document.querySelectorAll(".counter-system-data");

    counters.forEach((counter) => {
      let count = 0;
      const updateCounter = () => {
        const countTarget = parseInt(counter.getAttribute("data-count"));

        const change = (countTarget - count) / 5;
        count += change >= 0 ? Math.ceil(change) : Math.floor(change);

        if (count < countTarget) {
          counter.innerHTML = count;
          setTimeout(updateCounter, 10);
        } else {
          counter.innerHTML = countTarget;
        }
      };
      updateCounter();
    });
  }
};

onMounted(() => window.addEventListener("scroll", handleAnimate));

onUnmounted(() => window.removeEventListener("scroll", handleAnimate));

const { isVisibleBlog } = useBlog(2000);

const isCarouselTouchable = ref(false);
onMounted(async () => {
  await delay(1000);
  isCarouselTouchable.value = true;
});
</script>
<template>
  <div class="bg-[#EDF4FF]">
    <div class="home-page bg-[url('/images/home/bg-home.webp')] bg-no-repeat bg-cover">
      <section
        id="miraway-solution"
        class="bg-[url('/images/home/bg-intro.webp')] bg-no-repeat bg-cover py-[200px] max-lg:pb-[80px] max-lg:pt-24 px-[120px]"
        role="img"
        aria-label="miraway-website-homepage-cover"
      >
        <div class="2xl:container">
          <nuxt-picture
            loading="eager"
            src="/images/logo.png"
            width="238"
            class="max-lg:hidden"
            :img-attrs="{ alt: 'logo', height: 60, class: 'mx-auto w-full max-h-[60px] max-w-[238px]' }"
          />

          <div class="text-center mt-10">
            <h1 class="text-white heading-5">GIẢI PHÁP QUẢN TRỊ TRẢI NGHIỆM KHÁCH HÀNG ĐA KÊNH - CEM</h1>

            <h1 class="text-white mt-3 heading-5">HÀNG ĐẦU VIỆT NAM</h1>
          </div>

          <div class="list-service-home flex max-md:flex-col items-stretch justify-center gap-10 mt-36 max-lg:mt-10">
            <nuxt-link
              v-for="(service, index) in services"
              :key="'service-' + index"
              :to="service.redirect"
              class="block p-5 border-solid border-[1px] border-[#ffffff4d] lg:flex-1 hover:bg-[#141D38] hover:border-[#43baff]"
            >
              <nuxt-picture
                preload
                :src="service.image"
                width="24"
                :img-attrs="{ alt: service.alt, height: '24', class: 'mx-auto w-full max-h-[24px] max-w-[24px]' }"
              />

              <div class="text-center text-white font-semibold mt-5 uppercase">{{ service.title }}</div>
            </nuxt-link>
          </div>
        </div>
      </section>

      <section id="miraway-about" class="mt-[40px] max-md:mt-[80px] px-[120px] 2xl:container">
        <div class="text-center">
          <h2 class="font-bold text-primary heading-3">Về chúng tôi</h2>
          <div class="mt-4">Miraway tự hào là một trong những công ty hàng đầu về giải pháp công nghệ.</div>
        </div>

        <div class="mt-8 max-md:mt-4 flex flex-wrap justify-center gap-4">
          <nuxt-link to="/gioi-thieu">
            <button class="primary max-sm:w-full">Tìm hiểu thêm</button>
          </nuxt-link>

          <nuxt-link to="/giai-phap/cetm">
            <button class="max-sm:w-full four">Giải pháp</button>
          </nuxt-link>
        </div>

        <div class="mt-[40px]">
          <div class="flex max-sm:flex-col items-center gap-x-8 gap-y-5">
            <div class="max-md:flex-1 md:w-7/12">
              <h2 class="text-primary heading-4">Tầm nhìn</h2>
              <p class="mt-4 max-sm:mt-2 text-medium">
                Duy trì vị thế doanh nghiệp tiên phong dẫn đầu tại Việt Nam, đồng thời thực hiện mục tiêu đến năm 2025
                sẽ trở thành doanh nghiệp hàng đầu Đông Nam Á trong lĩnh vực cung cấp giải pháp chuyển đổi số công nghệ
                cao.
              </p>

              <h2 class="text-primary heading-4 mt-9">Sứ mệnh</h2>
              <p class="mt-4 max-sm:mt-2 text-medium">
                Với tâm niệm "Con người là tài sản lớn nhất", chúng tôi luôn khát vọng, sáng tạo và phát triển không
                ngừng để tạo ra những giải pháp công nghệ xuất sắc, đem lại những giá trị cao nhất cho quý khách hàng,
                góp phần to lớn thúc đẩy sự phát triển của nền công nghệ thông tin nước nhà và xây dựng dân tộc Việt Nam
                giàu mạnh hơn. Mỗi cán bộ nhân viên của Miraway không những giỏi về chuyên môn, tính chuyên nghiệp cao
                mà còn là những con người có ý thức tôn trọng đạo đức nghề nghiệp.
              </p>
            </div>

            <div class="max-md:flex-1 md:w-5/12 max-md:w-full">
              <nuxt-picture
                loading="lazy"
                src="/images/sang-tao-cong-nghe-kien-tao-dat-nuoc.png"
                width="933"
                :img-attrs="{
                  class: 'w-full max-md:max-h-[250px] object-contain',
                  alt: 'sang-tao-cong-nghe-kien-tao-dat-nuoc',
                  width: 270,
                  height: 207,
                }"
              />
            </div>
          </div>

          <div
            id="number-system-data"
            class="mt-[80px] max-sm:mt-[20px] flex max-sm:flex-col-reverse sm:items-center gap-x-28 max-lg:gap-16 max-sm:gap-5"
          >
            <div class="flex-1 flex justify-between">
              <div>
                <div class="">
                  <div class="flex items-center">
                    <h2 class="text-[#E15E50] counter-system-data" data-count="6000">0</h2>
                    <div class="text-[#E15E50] heading-2">+</div>
                  </div>
                  <div class="mt-2 text-bold">Điểm triển khai</div>
                </div>

                <div class="mt-9">
                  <div class="flex items-center">
                    <h2 class="text-[#E15E50] counter-system-data" data-count="15">0</h2>
                    <div class="text-[#E15E50] heading-2">+</div>
                  </div>
                  <div class="mt-2 text-bold">Năm kinh nghiệm</div>
                </div>
              </div>

              <div class="flex flex-col gap-y-[45px]">
                <div class="w-[1px] flex-1 bg-[#E6E8EC]" />
                <div class="w-[1px] flex-1 bg-[#E6E8EC]" />
              </div>

              <div>
                <div class="">
                  <div class="flex items-center">
                    <h2 class="text-[#E15E50] counter-system-data" data-count="500">0</h2>
                    <div class="text-[#E15E50] heading-2">+</div>
                  </div>
                  <div class="mt-2 text-bold">Khách hàng</div>
                </div>

                <div class="mt-9">
                  <div class="flex items-center">
                    <h2 class="text-[#E15E50] counter-system-data" data-count="8">0</h2>
                    <div class="text-[#E15E50] heading-2">+</div>
                  </div>
                  <div class="mt-2 text-bold">Hệ thống</div>
                </div>
              </div>
            </div>

            <div class="flex-1">
              <h2 class="text-primary heading-4">Những con số</h2>
              <div class="mt-4">
                Với mạng lưới đối tác toàn cầu và những dự án thực tế đã triển khai thành công trong và ngoài nước,
                Miraway tự tin về năng lực cung cấp và triển khai các giải pháp CNTT tại Việt Nam và các nước trong khu
                vực.
              </div>
            </div>
          </div>
        </div>

        <div class="mt-[100px] max-md:mt-[80px]">
          <h2 class="text-center text-primary heading-3">Giải pháp tiêu biểu</h2>

          <carousel
            :items-to-show="1"
            :wrapAround="true"
            class="mt-10 max-lg:mt-5"
            :touchDrag="isCarouselTouchable"
            :mouseDrag="isCarouselTouchable"
          >
            <slide v-for="(typicalSolution, index) in typicalSolutions" :key="'typicalSolution' + index">
              <div class="cursor-pointer h-full !flex max-lg:flex-col-reverse items-center justify-between gap-x-28">
                <div class="w-full flex flex-col lg:flex-col-reverse max-lg:items-center" v-if="!index">
                  <div class="flex justify-center shrink-0 w-full relative">
                    <nuxt-picture
                      loading="lazy"
                      :src="typicalSolution.image"
                      :img-attrs="{ alt: typicalSolution.alt, class: 'w-full' }"
                      class="max-lg:hidden"
                    />

                    <nuxt-picture
                      loading="lazy"
                      :src="typicalSolution.image.replace('.', '-mobile.')"
                      :img-attrs="{ alt: typicalSolution.alt, class: 'w-full' }"
                      class="lg:hidden"
                    />

                    <div class="absolute top-0 left-0 w-full h-full">
                      <div class="lg:w-2/3 mx-auto grid grid-cols-2 lg:gap-y-6 md:gap-x-14 lg:gap-x-20 h-full">
                        <nuxt-link
                          v-for="(link, index) in typicalSolution.links"
                          :key="'typicalSolution-' + index"
                          :to="link?.to || null"
                          class="block"
                          :aria-label="link?.ariaLabel || null"
                          :target="link.targetBlank ? '_blank' : null"
                        />
                      </div>
                    </div>
                  </div>

                  <h3 class="heading-5 max-lg:w-[80%] lg:text-center text-primary">
                    Quản trị trải nghiệm khách hàng đa kênh (CEM)
                  </h3>
                </div>

                <template v-else>
                  <div class="max-lg:h-[259px] max-lg:flex max-lg:flex-col text-left">
                    <div class="flex-1 grow">
                      <h2 class="text-primary heading-4">{{ typicalSolution.title }}</h2>
                      <div class="mt-4 max-lg:mt-2">{{ typicalSolution.description }}</div>
                    </div>

                    <nuxt-link
                      :to="typicalSolution.redirect"
                      class="block mt-8 max-lg:mt-4"
                      :target="typicalSolution.targetBlank ? '_blank' : null"
                    >
                      <button class="primary max-lg:w-full">Tìm hiểu thêm</button>
                    </nuxt-link>
                  </div>

                  <div class="shrink-0">
                    <nuxt-picture
                      loading="lazy"
                      :src="typicalSolution.image"
                      width="500"
                      :img-attrs="{ alt: typicalSolution.alt, class: typicalSolution?.classImgMobile || '' }"
                    />
                  </div>
                </template>
              </div>
            </slide>

            <template #addons>
              <pagination />
            </template>
          </carousel>
        </div>
      </section>

      <section id="miraway-value-delivered" class="mt-[100px] max-lg:mt-5 2xl:container">
        <h2 class="text-center text-primary heading-3">Giá trị mang lại</h2>

        <div class="px-[120px] relative mt-14 max-sm:mt-[40px]">
          <div class="sm:hidden absolute top-0 bottom-0 left-1/2 border-dashed border-r-2 border-[#CCE0FA]" />

          <div class="flex max-sm:flex-col justify-center gap-x-8 max-sm:gap-y-[72px]">
            <div class="w-4/12 max-sm:w-full max-sm:flex max-sm:flex-col-reverse">
              <div class="text-center text-[#777E90] max-sm:mt-6">
                Các doanh nghiệp cho rằng cạnh tranh chủ yếu dựa trên trải nghiệm khách hàng (theo Gartner, 2021)
              </div>

              <div class="sm:mt-6 relative">
                <nuxt-picture
                  loading="lazy"
                  src="/images/luc-giac.png"
                  :img-attrs="{ class: 'mx-auto', alt: 'customer-percent', height: '191' }"
                  width="173"
                />

                <div
                  class="w-[100px] h-[100px] heading-6 flex-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[calc(50%+3px)] bg-[#E15E50] rounded-full text-white"
                >
                  89%
                </div>
              </div>
            </div>

            <div class="w-4/12 max-sm:w-full max-sm:flex max-sm:flex-col-reverse">
              <div class="text-center text-[#777E90] max-sm:mt-6">
                Kiểm soát chất lượng dịch vụ theo thời gian thực (theo triển khai thực tế của Miraway)
              </div>

              <div class="mt-6 relative">
                <nuxt-picture
                  loading="lazy"
                  src="/images/luc-giac.png"
                  :img-attrs="{ class: 'mx-auto', alt: 'quantity-realtime', height: '191' }"
                  width="173"
                />

                <div
                  class="w-[100px] h-[100px] heading-6 flex-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[calc(50%+3px)] bg-[#0066E6] rounded-full text-white"
                >
                  99%
                </div>
              </div>
            </div>
          </div>

          <div class="flex max-sm:flex-col justify-center gap-x-8 max-sm:gap-y-[72px] max-sm:mt-[72px]">
            <div class="w-4/12 max-sm:w-full">
              <div class="mb-6 relative">
                <nuxt-picture
                  loading="lazy"
                  src="/images/luc-giac.png"
                  :img-attrs="{ class: 'mx-auto', alt: 'customer-experience', height: '191' }"
                  width="173"
                />

                <div
                  class="w-[100px] h-[100px] heading-6 flex-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[calc(50%+3px)] bg-[#0066E6] rounded-full text-white"
                >
                  86%
                </div>
              </div>

              <div class="text-center text-[#777E90]">
                Khách hàng sẵn sàng trả nhiều tiền hơn để nhận được trải nghiệm khách hàng tốt hơn (theo CX Index 2021)
              </div>
            </div>

            <div class="w-4/12 max-sm:w-full">
              <div class="mb-6 relative">
                <nuxt-picture
                  loading="lazy"
                  src="/images/luc-giac.png"
                  :img-attrs="{ class: 'mx-auto', alt: 'double-the-profit', height: '191' }"
                  width="173"
                />

                <div
                  class="w-[100px] h-[100px] heading-6 flex-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[calc(50%+3px)] bg-[#E15E50] rounded-full text-white"
                >
                  200%
                </div>
              </div>

              <div class="text-center text-[#777E90]">
                Đầu tư vào trải nghiệm khách hàng giúp doanh nghiệp tăng gấp đôi lợi nhuận trong vòng 3 năm (theo Temkin
                Group, 2018)
              </div>
            </div>

            <div class="w-4/12 max-sm:w-full">
              <div class="mb-6 relative">
                <nuxt-picture
                  loading="lazy"
                  src="/images/luc-giac.png"
                  :img-attrs="{ class: 'mx-auto', alt: 'omnichannel-customer-experience', height: '191' }"
                  width="173"
                />

                <div
                  class="w-[100px] h-[100px] heading-6 flex-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[calc(50%+3px)] bg-[#794496] rounded-full text-white"
                >
                  80%
                </div>
              </div>

              <div class="text-center text-[#777E90]">
                Số lượng doanh nghiệp đầu tư vào trải nghiệm khách hàng đa kênh tăng từ 20% lên hơn 80% (theo PWC, 2020)
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="miraway-customer" class="mt-[100px] max-lg:mt-[80px] px-[120px] pb-[50px]">
        <h3 class="text-primary text-center">Khách hàng của Miraway</h3>

        <div class="mt-14 max-md:mt-[40px] overflow-hidden flex">
          <div class="slide w-full shrink-0 max-md:hidden">
            <nuxt-picture
              loading="lazy"
              src="/images/customer-logo.png"
              :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
            />
          </div>

          <div class="slide slide-clone w-full shrink-0 max-md:hidden">
            <nuxt-picture
              loading="lazy"
              src="/images/customer-logo.png"
              :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
            />
          </div>

          <div class="slide w-full shrink-0 md:hidden">
            <nuxt-picture
              loading="lazy"
              src="/images/customer-logo-mobile.png"
              :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
            />
          </div>

          <div class="slide slide-clone w-full shrink-0 md:hidden">
            <nuxt-picture
              loading="lazy"
              src="/images/customer-logo-mobile.png"
              :img-attrs="{ class: 'w-full', alt: 'customer-logos' }"
            />
          </div>
        </div>
      </section>

      <LazyBlog v-if="isVisibleBlog" title="Tin tức và sự kiện" class="px-[120px] pb-10 2xl:container !mt-[50px]" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slide {
  animation: slideAnimation 25s linear infinite;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

::v-deep() {
  .carousel__pagination-button {
    &::after {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      opacity: 0.2;
      background-color: #000;
    }

    &--active::after {
      background-color: #007aff;
      opacity: 1;
    }
  }
}
</style>
